<template>
  <div
    ref="container"
    v-test="{ e2e: 'delivery-options active' }" />
</template>

<script>
import {
  EVENT_RENDER_DELIVERY_OPTIONS,
  EVENT_UPDATED_DELIVERY_OPTIONS,
  EVENT_UPDATE_CONFIG, EVENT_UPDATE_DELIVERY_OPTIONS,
} from '@/services/delivery-options/constants';
import Vue from 'vue';

export default {
  name: 'DeliveryOptions',
  props: {
    config: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      show: false,
      imported: false,
    };
  },

  watch: {
    'config.address'() {
      this.dispatch(EVENT_UPDATE_DELIVERY_OPTIONS);
      this.$emit('delivery-options:update');
    },

    'config.config': {
      deep: true,
      handler() {
        this.dispatch(EVENT_UPDATE_CONFIG);
      },
    },
  },

  async mounted() {
    // The lib build of the delivery options does not include Vue, but can't use it without providing it like this.
    global.Vue = Vue;

    const div = document.createElement('div');
    div.id = 'myparcel-delivery-options';
    this.$refs.container.appendChild(div);

    await this.importDeliveryOptions();
    this.dispatch(EVENT_RENDER_DELIVERY_OPTIONS);

    document.addEventListener(EVENT_UPDATED_DELIVERY_OPTIONS, this.onUpdatedDeliveryOptions);
  },

  beforeDestroy() {
    document.removeEventListener(EVENT_UPDATED_DELIVERY_OPTIONS, this.onUpdatedDeliveryOptions);
  },

  methods: {
    /**
     * Dispatch given event name as custom event with the current config as detail.
     *
     * @param {String} event
     */
    dispatch(event) {
      window.MyParcelConfig = this.config;
      document.dispatchEvent(new Event(event));
    },

    /**
     * Import the delivery options library.
     *
     * @returns {Promise<void>}
     */
    async importDeliveryOptions() {
      if (this.imported) {
        return;
      }

      await import('@myparcel/delivery-options/dist/myparcel.lib.js');
      this.imported = true;
    },

    /**
     * @param {CustomEvent} event
     */
    onUpdatedDeliveryOptions(event) {
      this.$emit('delivery-options:updated', event.detail);
    },
  },
};
</script>
